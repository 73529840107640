<template>
  <div>
    <div class="section-header" @click="handleClick">
      <div class="title-wrapper">
        <img v-if="node.icon" :src="node.icon" class="section-icon" alt="icon" />
        <span
          :class="[
            'section-title',
            node.expanded ? 'expanded' : '',
            (!node.subtitle && !node.icon) ? 'full-width' : '',
            (node.icon && !node.subtitle) ? 'full-width-icon' : ''
          ]">{{ node.title }}</span>
        <span v-if="node.subtitle && !node.icon" class="divider"></span>
        <span v-if="node.subtitle" class="section-subtitle">{{ node.subtitle }}</span>
      </div>
      <i :class="node.expanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down'" class="toggle-icon"></i>
    </div>
    <div class="section-content" v-if="node.expanded">
      <div 
        class="item" 
        v-for="(item, itemIndex) in node.items" 
        :key="itemIndex" 
        @click="updateItemStatus(item)"
        :class="{ 'active-item': item.status === 1 }">
        {{ item.name }}
      </div>
      <TreeNode 
        v-for="(childNode, index) in node.children" 
        :key="index" 
        :node="childNode"
        @item-clicked="handleItemClicked"
      />
    </div>
    <div class="section-divider"></div>
  </div>
</template>

<script>
export default {
  name: 'TreeNode',
  props: {
    node: Object,
  },
  methods: {
    handleClick() {
      this.$emit('toggle-node');
      this.$emit('item-clicked', { ...this.node, status: null });
    },
    updateItemStatus(item) {
      // Zmieniamy status lokalnie
      item.status = item.status === 1 ? 0 : 1;
      // Emitujemy cały obiekt `item`
      this.$emit('item-clicked', item);
    },
    handleItemClicked(event) {
      // Przekazujemy dalej event z dziecka
      this.$emit('item-clicked', event);
    }
  }
};
</script>

<style scoped>
.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  width: 358px;
  margin: 0 auto;
}

.title-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.section-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.section-title {
  font-size: 20px;
  font-weight: bold;
  width: 95px;
}

.section-title.full-width {
  width: 100%;
}

.section-title.full-width-icon {
  width: calc(100% - 34px); /* Odejmujemy szerokość ikonki */
}

.section-title.expanded {
  color: #1123FF;
}

.divider {
  height: 90%;
  border-left: 2px solid #000000;
  margin: 0 10px;
  position: absolute;
  left: 99px; /* Ustalamy stałą pozycję linii pionowej */
}

.section-subtitle {
  font-size: 16px;
  color: #666;
  margin-left: 28px; /* Odsunięcie od linii pionowej */
  text-align: left; /* Wyrównanie do lewej */
}

.toggle-icon {
  font-size: 16px;
  color: #333;
}

.section-content {
  padding: 10px 0;
  background-color: #f8faf9; /* Zmieniono kolor tła */
  border-radius: 4px;
  width: 358px;
  margin: 0 auto;
}

.item {
  font-size: 16px; /* Zmieniono rozmiar czcionki */
  padding: 10px;
  cursor: pointer;
  border-top: 1px solid #ddd;
  background-color: #f8faf9; /* Kolor tła dla item */
}

.item:first-child {
  border-top: none;
}

.item.active-item {
  font-weight: bold;
}

.item:hover {
  color: #1123FF;
}

.section-divider {
  height: 1px;
  background-color: #ddd;
  margin: 0;
  width: 358px;
  margin: 0 auto;
}
</style>
