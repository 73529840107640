<!-- <template>
    <header class="header">
      <div v-if="loading">
        <div class="subtitle blink">Szkolenie : {{ currentToDo.title }} jest przygotowywane . .</div>
      </div>
      <div v-else>
        <h3> Szkolenie : {{ currentToDo.title }}</h3>
      </div>
    </header>
    <DisplayPanel :item="selectedItem" />
    <h5>Pole : {{ nodes }}</h5>
    <section class="left-sidebar" :class="{ collapsed: isSidebarExpanded }">
      <div class="sidebar" :class="{ collapsed: isSidebarExpanded }">
        <div class="toggle-button-wrapper">
          <div class="toggle-line"></div>
          <div class="toggle-button" @click="toggleSidebar">
            <i :class="isSidebarExpanded ? 'fas fa-chevron-circle-right' : 'fas fa-chevron-circle-left'"></i>
          </div>
          <div class="toggle-line"></div>
        </div>
        <div class="sections" v-if="!isSidebarExpanded">
          <TreeNode v-for="(node, index) in nodes" :key="index" :node="node" @item-clicked="handleItemClick"
            @toggle-node="toggleNode(index)" />
        </div>
      </div>
    </section>
</template> -->
<!-- <template>
      <header class="header">
      <div v-if="loading">
        <div class="subtitle blink">Szkolenie : {{ currentToDo.title }} jest przygotowywane . .</div>
      </div>
      <div v-else>
        <div class="subtitle">Szkolenie : {{ currentToDo.title }}</div>
      </div>
    </header>
  <div class="sidebar" :class="{ collapsed: isSidebarExpanded }">
    <div class="toggle-button" @click="toggleSidebar">
      <i :class="isSidebarExpanded ? 'fas fa-chevron-circle-right' : 'fas fa-chevron-circle-left'"></i>
    </div>
    <div class="sidebar-content" v-if="!isSidebarExpanded">
      <TreeNode 
        v-for="(node, index) in nodes" 
        :key="index" 
        :node="node" 
        @item-clicked="handleItemClick"
        @toggle-node="toggleNode(index)" 
      />
    </div>
  </div>
  <DisplayPanel :item="selectedItem" />
</template> -->

<!-- <template>
  <div class="container">
    <div :class="['sidebar', { 'collapsed': !isSidebarExpanded }]">
      <div class="toggle-button" @click="toggleSidebar">
        <i :class="isSidebarExpanded ? 'fas fa-chevron-left' : 'fas fa-chevron-right'"></i>
      </div>
      <div v-if="isSidebarExpanded" class="sidebar-content">
        <TreeNode 
          v-for="(node, index) in nodes" 
          :key="index" 
          :node="node" 
          @item-clicked="handleItemClick"
          @toggle-node="toggleNode(index)" 
        />
      </div>
    </div>
    <div :class="['display-panel-wrapper', { 'full-width': !isSidebarExpanded }]">
      <DisplayPanel :item="selectedItem" />
    </div>
  </div>
</template> -->

<template>
      <header class="header">
      <div v-if="loading">
        <div class="subtitle blink">Szkolenie : {{ currentToDo.title }} jest przygotowywane . .</div>
      </div>
      <div v-else>
        <div class="subtitle">Szkolenie : {{ currentToDo.title }}</div>
      </div>
    </header>  <div class="container">
    <div :class="['sidebar', { 'collapsed': !isSidebarExpanded }]">
      <div class="toggle-button" @click="toggleSidebar">
        <i :class="isSidebarExpanded ? 'fas fa-chevron-left' : 'fas fa-chevron-right'"></i>
      </div>
      <div v-if="isSidebarExpanded" class="sidebar-content">
        <TreeNode 
          v-for="(node, index) in nodes" 
          :key="index" 
          :node="node" 
          @item-clicked="handleItemClick"
          @toggle-node="toggleNode(index)" 
        />
      </div>
    </div>
    <div :class="['display-panel-wrapper', { 'full-width': !isSidebarExpanded }]">
      <DisplayPanel :item="selectedItem" :items="flatItems" :currentIndex="selectedItemIndex" @navigate="navigateToItem" />
    </div>
  </div>
</template>

<script>
import UserService from "../services/user.service";
import TreeNode from './TreeNode.vue';
import DisplayPanel from './DisplayPanel.vue';

export default {
  components: {
    TreeNode,
    DisplayPanel
  },
  data() {
    return {
      isSidebarExpanded: true,
      selectedItem: null,
      selectedItemIndex: 0,
      nodes: [], // Pusta tablica jako początkowa wartość
      nodes1: [
        {
          title: 'WSTĘP NA JUTRO 1',
          subtitle: '',
          text: 'TEXT WSTĘP NA JUTRO 1 0',
          expanded: false,
          icon: '@/asset/Untitled-2-12.svg',
          items: [
            { name: 'Praca zdalna – definicja', text: 'TEXT WSTĘP NA JUTRO 12', status: 0 },
            { name: 'Decyzja o pracy zdalnej - wniosek pracownika', text: 'TEXT WSTĘP NA JUTRO 13', status: 0 },
            { name: 'Decyzja o pracy zdalnej - polecenie pracodawcy', text: 'TEXT WSTĘP NA JUTRO 14', status: 0 },
            { name: 'Regulamin wykonywania pracy zdalnej', text: 'TEXT WSTĘP NA JUTRO 15', status: 0 },
            { name: 'Pisemna informacja o warunkach zatrudnienia', text: 'TEXT WSTĘP NA JUTRO 16', status: 0 },
            { name: 'Koszty pracy zdalnej', text: 'TEXT WSTĘP NA JUTRO 17', status: 0 }
          ],
          children: []
        },
        {
          title: 'LE 12',
          subtitle: 'Decyzja o pracy zdalnej - polecenie pracodawcy',
          text: 'TEXT WSTĘP NA JUTRO 2',
          expanded: false,
          icon: '',
          items: [
            { name: 'Praca zdalna – definicja', text: 'TEXT WSTĘP NA JUTRO 21', status: 0 },
            { name: 'Decyzja o pracy zdalnej - wniosek pracownika', text: 'TEXT WSTĘP NA JUTRO 22', status: 0 },
            { name: 'Decyzja o pracy zdalnej - polecenie pracodawcy', text: 'TEXT WSTĘP NA JUTRO 23', status: 0 },
            { name: 'Regulamin wykonywania pracy zdalnej', text: 'TEXT WSTĘP NA JUTRO 24', status: 0 },
            { name: 'Pisemna informacja o warunkach zatrudnienia', text: 'TEXT WSTĘP NA JUTRO 25', status: 0 },
            { name: 'Koszty pracy zdalnej', text: 'TEXT WSTĘP NA JUTRO 26', status: 0 }
          ],
          children: []
        },
        {
          title: 'LEK 23',
          subtitle: 'Koszty pracy zdalnej',
          text: 'TEXT WSTĘP NA JUTRO 3',
          expanded: false,
          icon: '',
          items: [
            { name: 'Praca zdalna – definicja', text: 'TEXT WSTĘP NA JUTRO 31', status: 0 },
            { name: 'Decyzja o pracy zdalnej - wniosek pracownika', text: 'TEXT WSTĘP NA JUTRO 32', status: 0 },
            { name: 'Decyzja o pracy zdalnej - polecenie pracodawcy', text: 'TEXT WSTĘP NA JUTRO 33', status: 0 },
            { name: 'Regulamin wykonywania pracy zdalnej', text: 'TEXT WSTĘP NA JUTRO 34', status: 0 },
            { name: 'Pisemna informacja o warunkach zatrudnienia', text: 'TEXT WSTĘP NA JUTRO 35', status: 0 },
            { name: 'Koszty pracy zdalnej', text: 'TEXT WSTĘP NA JUTRO 36', status: 0 }
          ],
          children: []
        },
        {
          title: 'WSTĘP',
          subtitle: '',
          text: 'TEXT WSTĘP NA JUTRO 4',
          expanded: false,
          icon: '',
          items: [
            { name: 'Praca zdalna – definicja', text: 'TEXT WSTĘP NA JUTRO 41', status: 0 },
            { name: 'Decyzja o pracy zdalnej - wniosek pracownika', text: 'TEXT WSTĘP NA JUTRO 42', status: 0 },
            { name: 'Decyzja o pracy zdalnej - polecenie pracodawcy', text: 'TEXT WSTĘP NA JUTRO 43', status: 0 },
            { name: 'Regulamin wykonywania pracy zdalnej', text: 'TEXT WSTĘP NA JUTRO 44', status: 0 },
            { name: 'Pisemna informacja o warunkach zatrudnienia', text: 'TEXT WSTĘP NA JUTRO 45', status: 0 },
            { name: 'Koszty pracy zdalnej', text: 'TEXT WSTĘP NA JUTRO 46', status: 0 }
          ],
          children: []
        },
        {
          title: 'WSTĘP NA JUTRO',
          subtitle: '',
          text: 'TEXT WSTĘP NA JUTRO 5',
          expanded: false,
          icon: '',
          items: [
            { name: 'Praca zdalna – definicja', text: 'TEXT WSTĘP NA JUTRO 51', status: 0 },
            { name: 'Decyzja o pracy zdalnej - wniosek pracownika', text: 'TEXT WSTĘP NA JUTRO 52', status: 0 },
            { name: 'Decyzja o pracy zdalnej - polecenie pracodawcy', text: 'TEXT WSTĘP NA JUTRO 53', status: 0 },
            { name: 'Regulamin wykonywania pracy zdalnej', text: 'TEXT WSTĘP NA JUTRO 54', status: 0 },
            { name: 'Pisemna informacja o warunkach zatrudnienia', text: 'TEXT WSTĘP NA JUTRO 55', status: 0 },
            { name: 'Koszty pracy zdalnej', text: 'TEXT WSTĘP NA JUTRO 56', status: 0 }
          ],
          children: []
        },
        {
          title: 'LEKCJA 1',
          subtitle: 'Decyzja o pracy zdalnej - polecenie pracodawcy',
          text: 'TEXT WSTĘP NA JUTRO 6',
          expanded: false,
          icon: '',
          items: [
            { name: 'Praca zdalna – definicja', text: 'TEXT WSTĘP NA JUTRO 61', status: 0 },
            { name: 'Decyzja o pracy zdalnej - wniosek pracownika', text: 'TEXT WSTĘP NA JUTRO 62', status: 0 },
            { name: 'Decyzja o pracy zdalnej - polecenie pracodawcy', text: 'TEXT WSTĘP NA JUTRO 63', status: 0 },
            { name: 'Regulamin wykonywania pracy zdalnej', text: 'TEXT WSTĘP NA JUTRO 64', status: 0 },
            { name: 'Pisemna informacja o warunkach zatrudnienia', text: 'TEXT WSTĘP NA JUTRO 65', status: 0 },
            { name: 'Koszty pracy zdalnej', text: 'TEXT WSTĘP NA JUTRO 66', status: 0 }
          ],
          children: []
        },
        {
          title: 'LEKC 2',
          subtitle: 'Koszty pracy zdalnej',
          text: 'TEXT WSTĘP NA JUTRO 7',
          expanded: false,
          icon: '',
          items: [
            { name: 'Praca zdalna – definicja', text: 'TEXT WSTĘP NA JUTRO 71', status: 0 },
            { name: 'Decyzja o pracy zdalnej - wniosek pracownika', text: 'TEXT WSTĘP NA JUTRO 72', status: 0 },
            { name: 'Decyzja o pracy zdalnej - polecenie pracodawcy', text: 'TEXT WSTĘP NA JUTRO 73', status: 0 },
            { name: 'Regulamin wykonywania pracy zdalnej', text: 'TEXT WSTĘP NA JUTRO 74', status: 0 },
            { name: 'Pisemna informacja o warunkach zatrudnienia', text: 'TEXT WSTĘP NA JUTRO 75', status: 0 },
            { name: 'Koszty pracy zdalnej', text: 'TEXT WSTĘP NA JUTRO 76', status: 0 }
          ],
          children: ['asdfasdf dsa fsafd sa fsa ', 'dsafgs s hdhdhd fhdfh']
        },
        {
          title: 'WSTĘP NA DZIŚ',
          subtitle: '',
          text: 'TEXT WSTĘP NA JUTRO 8',
          expanded: false,
          icon: '',
          items: [
            { name: 'Praca zdalna – definicja', text: 'TEXT WSTĘP NA JUTRO 82', status: 0 },
            { name: 'Decyzja o pracy zdalnej - wniosek pracownika', text: 'TEXT WSTĘP NA JUTRO 83', status: 0 },
            { name: 'Decyzja o pracy zdalnej - polecenie pracodawcy', text: 'TEXT WSTĘP NA JUTRO 84', status: 0 },
            { name: 'Regulamin wykonywania pracy zdalnej', text: 'TEXT WSTĘP NA JUTRO 85', status: 0 },
            { name: 'Pisemna informacja o warunkach zatrudnienia', text: 'TEXT WSTĘP NA JUTRO 86', status: 0 },
            { name: 'Koszty pracy zdalnej', text: 'TEXT WSTĘP NA JUTRO 87', status: 0 }
          ],
          children: []
        },
      ],
      loading: false,
      content: Array,
      toDoItem: Object,
      currentIndex: 0
    };
  },
  methods: {
    flattenNodes(nodes) {
      //console.log('Flattening nodes:', nodes);
      if (!nodes || nodes.length === 0) return [];
      let items = [];
      nodes.forEach(node => {
        items.push(node);
        if (node.items && node.expanded) {
          items = items.concat(this.flattenNodes(node.items));
        }
      });
      return items;
    },
    toggleSidebar() {
      this.isSidebarExpanded = !this.isSidebarExpanded;
    },
    toggleNode(index) {
      this.nodes[index].expanded = !this.nodes[index].expanded;
    },
    handleItemClick(item, index) {
      this.selectedItem = item;
      this.selectedItemIndex = index;
    },
    navigateToItem(index) {
      this.selectedItem = this.flatItems[index];
      this.selectedItemIndex = index;
    }
  },
  computed: {
    flatItems() {
      return this.flattenNodes(this.nodes);
    },
    currentToDo() {
      return this.$store.userToDo;
      // return this.$store;

    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  
  mounted() {
    this.loading = true;
    UserService.getWorkBoard(this.$store.state.auth.user.claims.id, this.$store.userToDo.id).then(
      (response) => {
        this.loading = false;
        this.nodes = response.data;
      },
      (error) => {
        this.loading = false;
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>

<style scoped>
.container {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 410px;
  box-shadow: 0px 3px 6px #00000029;
  transition: width 0.3s ease;
  position: relative;
}

.collapsed {
  width: 40px;
}

.toggle-button {
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
}

.toggle-button i {
  font-size: 16px;
  color: #333;
}

.display-panel-wrapper {
  flex-grow: 1;
  transition: width 0.3s ease;
}

.full-width {
  width: 100%;
}

.sidebar-content {
  width: 358px;
  margin: 0 auto;
  padding-top: 20px;
}
</style>