<template>
  <div class="display-panel">
    <div v-if="item">
      <h1>{{ item.name }}</h1>
      <p>{{ item.subtitle || item.text }}</p>
    </div>
    <div class="navigation-buttons">
      <!-- <button @click="prevItem" :disabled="currentIndex === 0">Cofnij</button>
      <button @click="nextItem" :disabled="currentIndex === items.length - 1">Dalej</button> -->
      <button @click="prevItem" >Cofnij</button>
      <button @click="nextItem" >Dalej</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    currentIndex: {
      type: Number,
      required: true
    }
  },
  methods: {
    prevItem() {
      if (this.currentIndex > 0) {
        this.$emit('navigate', this.currentIndex - 1);
      }
    },
    nextItem() {
      // if (this.currentIndex < this.items.length - 1) {
      //   this.$emit('navigate', this.currentIndex + 1);
   
          this.$emit('navigate', this.currentIndex + 1);
  
    }
  }
}
</script>

<style scoped>
.display-panel {
  padding: 20px;
  background-color: white;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  border: 1px solid #a1a1a1;
  border-radius: 20px;
  background-color: white;
  cursor: pointer;
  font-size: 16px;
  color: #5a5a5a;
  transition: background-color 0.3s, color 0.3s;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

button:not(:disabled):hover {
  background-color: #1123FF;
  color: white;
}
</style>
